import { Organisme } from './../../gestion-campagnes/fiche-campagnes/fiche-campagnes.component';
import { ListCategorieComponent } from './../../gestion-categorie/list-categorie/list-categorie.component';
import { environment } from 'src/environments/environment';
import { BankInformation } from './../../../../entity/BankInformation';
import { NotificationsService } from './../../../../shared/NotificationsService/notifications.service';
import { TypeUser } from './../../../../entity/User/TypeUser';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { tap, map, startWith, debounceTime } from 'rxjs/operators';

import { CycleVie } from 'src/app/entity/CycleVie';
import { ListOrganisme } from 'src/app/entity/Generic/ListOrganisme';
import { Campagne } from 'src/app/entity/Opportunity/Campagne';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { ApiProduitsService } from 'src/app/services/ApiPoduits/api-poduits.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import { ApiOrganismeService } from 'src/app/services/ApiOrganisme/api-organisme.service';
import { ApiAdminService } from 'src/app/services/ApiAdmin/api-admin.service';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import swal from 'sweetalert2';
import { EstablishmentInformation } from 'src/app/entity/Opportunity/EstablishmentInformation';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Client } from 'src/app/entity/Generic/brand/client';
import { Distributeur, listetype, listPerso } from 'src/app/entity/Generic/brand/distributeur';
import { Parcours } from 'src/app/entity/Generic/brand/parcours';
import { Brand } from 'src/app/entity/Generic/brand/brand';
import { FR as France } from '../../../opportunities/add-opportunite/Elements/add_prospect/prospect-pro-form/json-dict/fr';
import { TN as Tunis } from '../../../opportunities/add-opportunite/Elements/add_prospect/prospect-pro-form/json-dict/tn';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { FormModel } from 'src/app/entity/Formbuilder/FormModel';
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
class formbuilder {
  data;
  schema;
}
interface Couleur {
  a: number;
  b: number;
  g: number;
  hex: string;
  r: number;
  rgba: string;
  roundA: number;
}
@Component({
  selector: 'fiche-add-organisme',
  templateUrl: './fiche-organisme.component.html',
  styleUrls: ['./fiche-organisme.component.css'],
})
export class FicheOrganismeComponent implements OnInit, OnDestroy {
  @ViewChild('tabs', { static: false }) tabs;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('campagneInput') campagneInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('Autocomplete') Autocomplete: MatAutocomplete;
  @ViewChild('myForm') myForm: NgForm;

  initial_Robot: string = '';

  allowed_to_update: boolean = true;
  listeOfUnsubscribeWebService: Subscription[] = [];
  listTypeOrganisme: TypeUser[] = [];
  campagne: Campagne = new Campagne();
  campagneId: string = '';
  cycleVie: CycleVie = new CycleVie();
  horsCamp: boolean = false;
  selected_cyclevie: string = '';
  listCycleVieStr: any[];
  listCycleVie: any;
  listCampagnes: any;
  couleurDist2: any;
  couleurDist1: any;
  id_manager: any;
  listCampagne: any[] = [];
  listIdCampagne: any[] = [];
  organisme: ListOrganisme = new ListOrganisme();
  initialorganisme: ListOrganisme = new ListOrganisme();
  espace_distributeur: Distributeur = new Distributeur();
  espace_client: Client = new Client();
  parcours_souscription: Parcours = new Parcours();
  MyAdresse: string = '';
  hide = true;
  loaderListCompagnie: boolean = false;
  listLayout = ['Classic', 'Classy', 'Modern', 'Dense', 'Futuristic'];
  listTheme = [
    { name: 'Default', color: '#4f46e5' },
    { name: 'Band', color: '#4f46e5' },
    { name: 'Teal', color: '#0d9488' },
    { name: 'Rose', color: '#f43f5e' },
    { name: 'Purple', color: '#9333ea' },
    { name: 'Amber', color: '#f59e0b' },
    { name: 'Green', color: '#cdff00' },
    { name: 'Lime', color: '#84cc16' },
    { name: 'sky', color: '#0ea5e9' },
    { name: 'Indigo', color: '#6366f1' },
    { name: 'Pink', color: '#ec4899' },
    { name: 'Orange', color: '#f97316' },
    { name: 'Red', color: '#ef4444' },
    { name: 'Neutral', color: '#737373' },
    { name: 'Fuchsia', color: '#d946ef' },
    { name: 'Yellow', color: '#eab308' },
  ];

  listSchema = ['Dark', 'Light'];
  listFont: any;
  dropdownSettingsCampagne = {
    singleSelection: false,
    idField: 'id',
    textField: 'campagne',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nom_user',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  listusersSelected: any[] = [];
  campagnie: any[] = [];
  campagnieSelected: string[] = [];
  show_loading_add: boolean = false;
  initial_campagne: string = '';
  id_organisme: string = '';
  ModeAjout: boolean = true;
  organisme_id: string = '';
  show_logo: any = '../../../../assets/img/no-image.png';
  show_images: any = '../../../../assets/img/no-image.png';
  logo: any = '../../../../assets/img/no-image.png';
  favicon: any = '../../../../assets/img/no-image.png';
  background_images: any = '../../../../assets/img/no-image.png';
  show_favicon: any = '../../../../assets/img/no-image.png';
  show_backgoundImage: any = '../../../../assets/img/no-image.png';
  show = false;
  showF = false;
  showB = false;
  userCtrl: FormControl = new FormControl();
  campagneCtrl = new FormControl('', [Validators.required]);
  filteredUsers: Observable<any[]>;
  filteredCampagne: Observable<any[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  list_invalid: any;
  list_invalid_string: string;
  logo_organisme: any = '';
  favicon_organisme: any = '';
  background_image: any = '';
  visible = false;
  selectable = true;
  removable = true;
  organismeLibelle: string = '';
  userList: any[];
  isTypeMaster: string = '';
  establishmentInformation: EstablishmentInformation = new EstablishmentInformation();
  Organismes: OrganismeListe[] = [];
  brand: Brand = new Brand();
  couleur1: any = '';
  couleur2: any = '';
  couleur3: any = '';
  couleur4: any = '';
  couleur5: any = '';
  couleur6: any = '';
  couleur7: any = '';
  couleur8: any = '';
  couleur9: any = '';
  couleur0: any = '';
  couleurs: string[] = [];
  couleurss: Couleur[] = [];
  image0: '../../../../assets/img/no-image.png';
  image2: '../../../../assets/img/no-image.png';
  image1: '../../../../assets/img/no-image.png';
  image3: '../../../../assets/img/no-image.png';
  image4: '../../../../assets/img/no-image.png';
  image5: '../../../../assets/img/no-image.png';
  image6: '../../../../assets/img/no-image.png';
  image7: '../../../../assets/img/no-image.png';
  image8: '../../../../assets/img/no-image.png';
  image9: '../../../../assets/img/no-image.png';
  images: File[];
  couleur_principale: any = '';
  couleur_secondaire: any = '';
  font_family: string = '';
  font_familyy: string = '';
  listImage: string[] = [
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
    '../../../../assets/img/no-image.png',
  ];

  list2: any[] = [];

  imageSrc: string;
  list: any[] = [];
  getlistImage: string[] = [];
  couleur_theme: string = '';
  loyout: string = '';
  mode: string = '';
  index: any;
  couleur11: string;
  couleurrs: string[] = [];
  colorList: string[] = [];
  img1: any = '../../../../assets/img/no-image.png';
  img2: any = '../../../../assets/img/no-image.png';
  img3: any = '../../../../assets/img/no-image.png';
  img9: any = '../../../../assets/img/no-image.png';
  img8: any = '../../../../assets/img/no-image.png';
  img7: any = '../../../../assets/img/no-image.png';
  img6: any = '../../../../assets/img/no-image.png';
  img5: any = '../../../../assets/img/no-image.png';
  img4: any = '../../../../assets/img/no-image.png';
  img0: any = '../../../../assets/img/no-image.png';
  showImage1 = false;
  showImage2 = false;
  listdic: any = [];
  k: string;
  listIndice: any = [];
  couleur_client1: any = '';
  couleur_client2: any = '';
  any = '';
  couleur_client3: any = '';
  telCountry = environment.country;
  countryLanguage: any;
  formBuilderData: any;
  listOfUnsubscribeWebService: Subscription[] = [];
  keyFormOrganisme: string;
  model: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  form = new FormGroup({});
  formdataspecif: ListOrganisme;
  appear: boolean = false;
  delele: boolean = false;

  listColumnOffre: listPerso[] = [
    {
      key: ['prospects_name', 'prospects_surname'],
      order: 0,
      libelle: 'Prospect',
    },
    {
      key: ['name'],
      order: 1,
      libelle: 'Crée par',
    },
    {
      key: ['organismes_nom'],
      order: 2,
      libelle: 'Organisme',
    },
    {
      key: ['date_depot'],
      order: 3,
      libelle: 'Date de création',
    },
    {
      key: ['prix'],
      order: 4,
      libelle: ' Prime annuelle',
    },
    {
      key: ['etat'],
      order: 5,
      libelle: 'Statut',
    },
    {
      key: ['commission'],
      order: 6,
      libelle: 'Comission',
    },
  ];

  listColumnOffreDone: listPerso[] = [];

  listColumnContrat: listPerso[] = [
    {
      key: ['prospects_name', 'prospects_surname'],
      order: 0,
      libelle: 'Client',
    },
    {
      key: ['commerciaux_nom', 'commerciaux_prenom'],
      order: 1,
      libelle: 'Crée par',
    },
    {
      key: ['data_form_prod.etchingNumber'],
      order: 1,
      libelle: 'Numéro de gravage',
    },
    {
      key: ['affaire_num_contrat'],
      order: 1,
      libelle: 'Numéro du contrat',
    },
    {
      key: ['organismes_nom'],
      order: 2,
      libelle: 'Organisme',
    },
    {
      key: ['affaire_date_creation'],
      order: 3,
      libelle: 'Date de création',
    },

    {
      key: ['etat', 'etat_color', 'etat_background'],
      order: 5,
      libelle: 'Statut',
    },
    {
      key: ['comission'],
      order: 6,
      libelle: 'Comission',
    },
    {
      key: ['affaire_date_deff'],
      order: 7,
      libelle: "Date d'effet",
    },
    {
      key: ['game_prod'],
      order: 8,
      libelle: 'Gamme',
    },
    {
      key: ['nom_produit'],
      order: 9,
      libelle: 'Produit',
    },
    {
      key: ['contrat_type'],
      order: 10,
      libelle: 'Ligne de produit',
    },
    {
      key: ['cmp_denomination'],
      order: 11,
      libelle: 'Cie',
    },
    {
      key: ['affaire_mca'],
      order: 12,
      libelle: ' Prime annuelle',
    },
    {
      key: ['affaire_amc'],
      order: 13,
      libelle: 'Prime mensuelle',
    },
  ];

  listColumnContratDone: listPerso[] = [];
  alerts: any;
  buttons: any;
  org: any;
  navbarTxt: string = '';
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiOrgaismeServices: ApiOrganismeService,
    private apiAdminService: ApiAdminService,
    private routes: Router,
    private route: ActivatedRoute,
    private apiOpportunitService: ApiOpportunitService,
    private apiProduitService: ApiProduitsService,
    private notificationsService: NotificationsService,
    private apiCom: ApiComminucationService,
    private ApiCampagniesService: ApiCampagniesService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiConfigurationService: ApiConfigurationService,
    private formlyJsonschema: FormlyJsonschema,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.listeOfUnsubscribeWebService.forEach((element) => {
      element?.unsubscribe();
    });

    this.apiAdminBpmServices.sharedFormbuilder = {};
    this.listOfUnsubscribeWebService.forEach((element) => {
      element?.unsubscribe();
    });

    const currentRoute = this.routes.url;
    if (!currentRoute.includes('gestion-organisme')) {
      localStorage.removeItem('search');
      localStorage.removeItem('page');
    }
    
  }

  ngDoCheck() {
    this.tabs?.realignInkBar();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.org = object.organisme;
      });
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.org = object.organisme;
    });

    this.showloader();
    this.keyFormOrganisme = environment.keyFormOrganisme;

    if (this.telCountry === 'TN') {
      this.countryLanguage = Tunis.data;
    } else if (this.telCountry === 'FR') {
      this.countryLanguage = France.data;
    } else {
      this.countryLanguage = France.data;
    }
    this.apiOrgaismeServices.getListFontFamily().subscribe((data) => {
      this.listFont = data.items;
    });
    // this.list=[ ,  , , , , , , , , ]
    this.tabs?.realignInkBar();
    this.filteredCampagne = this.campagneCtrl.valueChanges.pipe(
      startWith(null),
      map((campagne: string | null) => (campagne ? this._filterCampagne() : this.campagnie.slice()))
    );

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt = object.navbar.gestionOrganisme + ' ➜ ' + object.navbar.Ajouter;
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt = object.navbar.gestionOrganisme + ' ➜ ' + object.navbar.Ajouter;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
    });

    this.getTypeOrganisme();
    this.getListCampagne();
    this.getListUser();

    this.route.paramMap.subscribe((params) => {
      if (params.get('organisme_id') !== 'nouveau') {
        this.translate.get('languages').subscribe((object: any) => {
          this.navbarTxt = object.navbar.gestionOrganisme + ' ➜ ' + object.navbar.Modifier;
          this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        });
        this.translate.onLangChange.subscribe(() => {
          this.translate.get('languages').subscribe((object: any) => {
            this.navbarTxt = object.navbar.gestionOrganisme + ' ➜ ' + object.navbar.Modifier;
            this.sharedMenuObserverService.updateMenu(this.navbarTxt);
          });
        });

        this.ModeAjout = false;
        this.organisme_id = params.get('organisme_id');
        this.getListOrganisme();
        this.apiOrgaismeServices.GetOrganismeById(this.organisme_id).subscribe((data) => {
          this.organisme = data;

          if (
            !this.organisme.form ||
            Object.keys(this.organisme.form).length === 0 ||
            Object.keys(this.organisme.form.schema).length === 0 ||
            Object.keys(this.organisme.form.schema.properties).length === 0
          ) {
            this.appear = false;
          } else {
            this.appear = true;
          }
          this.form = new FormGroup({});
          if (this.organisme.form != null) {
            this.formdataspecif = this.organisme.form;
            this.options = {};
            if (this.organisme.form?.schema) {
              const form: any = this.organisme.form?.schema?.properties;

              this.organisme.form.schema.properties = {};
              this.model = {};
              if (form !== undefined) {
                form.forEach((element) => {
                  this.organisme.form.schema.properties[element.name] = element;
                  if (this.model[element.name]) this.model[element.name] = element.default;
                });
              }
              for (const [key, value] of Object.entries(this.organisme.form?.data)) {
                if (value != null) this.model[key] = value;
              }
              //  this.fields = [
              //    this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.organisme.form.schema))),
              //  ];
              this.fields = [
                {
                  ...this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.organisme.form.schema))),
                  hooks: {
                    onInit: (field) => {
                      return field.formControl.valueChanges.pipe(
                        debounceTime(1500),
                        tap((value) => {
                          this.model = value;
                        })
                      );
                    },
                  },
                },
              ];
            }
          } else if (this.organisme.form != null) {
            this.options = {};
            this.formdataspecif = this.organisme.form;

            if (this.organisme.form?.schema) {
              this.fields = [
                {
                  ...this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.organisme.form.schema))),
                  hooks: {
                    onInit: (field) => {
                      return field.formControl.valueChanges.pipe(
                        debounceTime(1500),
                        tap((value) => {
                          this.model = value;
                        })
                      );
                    },
                  },
                },
              ];
            }
          }

          if (this.organisme.logo !== null) {
            this.logo = this.organisme.logo;
          }
          if (this.organisme.favicon !== null) {
            this.favicon = this.organisme.favicon;
          }
          if (this.organisme.background_image !== null) {
            this.background_images = this.organisme.background_image;
          }

          this.list = this.organisme.images;
          for (const i in this.list) {
            this.list2[i] = this.list[i];
          }

          this.image1 = this.list2[0];
          this.image2 = this.list2[1];
          this.image3 = this.list2[2];
          this.image4 = this.list2[3];
          this.image5 = this.list2[4];
          this.image6 = this.list2[5];
          this.image7 = this.list2[6];
          this.image8 = this.list2[7];
          this.image9 = this.list2[8];
          this.image0 = this.list2[9];

          this.getlistImage.push(
            this.image1,
            this.image2,
            this.image3,
            this.image4,
            this.image5,
            this.image6,
            this.image7,
            this.image8,
            this.image9,
            this.image0
          );
          if (
            this.organisme.brand.espace_client ||
            this.organisme.brand.espace_distributeur ||
            this.organisme.brand.parcours_souscription
          ) {
            this.espace_client = this.organisme.brand.espace_client;
            this.espace_distributeur = this.organisme.brand.espace_distributeur;
            this.parcours_souscription = this.organisme.brand.parcours_souscription;
            this.couleur_principale = this.espace_client?.couleur_principale;
            this.couleur_secondaire = this.espace_client.couleur_secondaire;
            this.couleur_client1 = this.espace_client.couleur3;
            this.couleur_client2 = this.espace_client.couleur4;
            this.couleur_client3 = this.espace_client.couleur5;
            this.font_family = this.espace_client.font_family;
            this.mode = this.espace_distributeur.mode;
            this.couleurDist1 = this.espace_distributeur.couleur1;
            this.couleur_theme = this.espace_distributeur.couleur_theme;
            this.loyout = this.espace_distributeur.loyout;
            if (this.espace_distributeur.list_offres)
              this.listColumnOffreDone = this.espace_distributeur.list_offres.included;
            if (this.espace_distributeur.list_offres)
              this.listColumnOffre = this.espace_distributeur.list_offres.not_included;
            if (this.espace_distributeur.list_contrats)
              this.listColumnContratDone = this.espace_distributeur.list_contrats.included;
            if (this.espace_distributeur.list_contrats)
              this.listColumnContrat = this.espace_distributeur.list_contrats.not_included;
            this.font_familyy = this.parcours_souscription.font_family;

            this.couleurs = this.parcours_souscription.couleurs;
            for (const i in this.couleurs) {
              if (this.couleurs[i] !== 'null') {
                this.couleurrs[i] = this.couleurs[i];
              } else {
                this.couleurrs[i] = '';
              }
            }

            this.couleur1 = this.couleurrs[0];
            this.couleur2 = this.couleurrs[1];
            this.couleur3 = this.couleurrs[2];
            this.couleur4 = this.couleurrs[3];
            this.couleur5 = this.couleurrs[4];
            this.couleur6 = this.couleurrs[5];
            this.couleur7 = this.couleurrs[6];
            this.couleur8 = this.couleurrs[7];
            this.couleur9 = this.couleurrs[8];
            this.couleur0 = this.couleurrs[9];
          }
          this.list = [];
          this.isTypeMaster = this.getTypeMaster(this.organisme.id_type);
          this.campagnieSelected = data.libelle_campagnes;
          this.listIdCampagne = data.campagnes;
          this.initial_Robot = data.nom_robot;
          Object.assign(this.initialorganisme, this.organisme);
          this.hideloader();
        });
        // this.listeOfUnsubscribeWebService.push(unsubscribeGetOrganismeById);
      } else {
        this.getListOrganisme();
        this.apiConfigurationService.getParamConfig(this.keyFormOrganisme).subscribe((Response) => {
          this.form = new FormGroup({});
          if (Response.value != null) {
            this.formdataspecif = Response.value;
            this.options = {};
            if (
              !this.organisme.form ||
              Object.keys(this.organisme.form).length === 0 ||
              Object.keys(this.organisme.form.schema.properties).length === 0
            ) {
              this.appear = false;
            } else {
              this.appear = true;
            }
            if (Response.value.schema) {
              const form: any = Response.value.schema.properties;
              if (Response.value.schema?.properties.length > 0) {
                this.appear = true;
              } else {
                this.appear = false;
              }
              Response.value.schema.properties = {};
              this.model = {};
              form.forEach((element) => {
                Response.value.schema.properties[element.name] = element;
                if (this.model[element.name]) this.model[element.name] = element.default;
              });
              /*  for (const [key, value] of Object.entries(this.organisme.form.data.data)) {
                   if (value != null)
                     this.model[key] = value
                 } */
              this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(Response.value.schema)))];
            }
          } else if (Response.value != null) {
            this.options = {};
            this.formdataspecif = Response.value;

            if (Response.value.schema) {
              this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(Response.value.schema)))];
            }
            // this.model = this.organisme.form.data?.data;
          }
        });
        this.hideloader();
      }
    });
  }

  onFileChange(event: any, i: number, key: any) {
    const reader = new FileReader();
    this.list2[i] = 0;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = event.target.files[0];
        this.show_images = reader.result;
        this.listImage[i] = this.show_images;

        this.img1 = this.listImage[0];
        this.img2 = this.listImage[1];
        this.img3 = this.listImage[2];
        this.img4 = this.listImage[3];
        this.img5 = this.listImage[4];
        this.img6 = this.listImage[5];
        this.img7 = this.listImage[6];
        this.img8 = this.listImage[7];
        this.img9 = this.listImage[8];
        this.img0 = this.listImage[9];
        this.list[i] = event.target.files[0];
        this.listIndice.push(i.toString());
        this.k = i.toString();
        const j = {
          key: this.k,
          value: event.target.files[0],
        };
        this.listdic[i] = j;

        this.organisme.images = this.list;
      };
    }
  }

  getLogo(event: any, type: string) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if (type === 'logo') {
            if (width <= 168 && height <= 70) {
              this.show = true;
              this.logo_organisme = event.target.files[0];
              this.show_logo = reader.result;
              this.organisme.logo = this.logo_organisme;
            } else {
              // alert('Taille trop grande! , longeur: ' + width + ' largeur: ' + height);
              this.alertError(this.alerts.tailleGrande);
            }
          }
          if (type === 'backgoundImage') {
            this.showB = true;
            this.background_image = event.target.files[0];
            this.show_backgoundImage = reader.result;
            this.organisme.background_image = this.background_image;
          }
          if (type === 'favicon') {
            if (width <= 32 && height <= 32) {
              this.showF = true;
              this.favicon_organisme = event.target.files[0];
              this.show_favicon = reader.result;
              this.organisme.favicon = this.favicon_organisme;
            } else {
              this.alertError(this.alerts.tailleGrande32);
            }
          }
        };
      };
    }
  }

  alertSuccess(data, idOrg) {
    let textModifier = '';
    if (this.ModeAjout) textModifier = this.buttons.modifyOrg;
    else textModifier = this.buttons.fermer;
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        denyButtonText: this.buttons.newOrg,
        denyButtonColor: '833626',
        cancelButtonText: textModifier,
        confirmButtonText: this.buttons.listOrg,
      })
      .then((result) => {
        if (result.isDismissed) {
          this.hideloader();
          if (this.ModeAjout) this.routes.navigate(['/gestion-organisme/fiche/' + idOrg]);
        }
        if (result.isConfirmed) {
          this.hideloader();
          this.routes.navigate(['/gestion-organisme']);
        }
        if (result.isDenied) {
          this.hideloader();
          this.organisme = new ListOrganisme();
          this.myForm.resetForm();
          this.routes.navigate(['/gestion-organisme/fiche/nouveau']);
          setTimeout(() => {
            this.ngOnInit();
          }, 400);
        }
      });
  }

  getAttributName(name) {
    let nameReturned = '';
    switch (name) {
      case 'nom_organisme':
        nameReturned = this.org.NomOrganisme;
        break;

      case 'id_type':
        nameReturned = this.org.Type;
        break;

      case 'nom_organisme_master':
        nameReturned = this.org.OrganismeMaster;
        break;
      case 'mail':
        nameReturned = this.alerts.formatEmail;
        break;

      case 'mail_gestion':
        nameReturned = this.alerts.formatEmailGestion;
        break;

      case 'siren':
        nameReturned = this.alerts.formatSiren;
        break;

      case 'siret':
        nameReturned = this.alerts.formatSiret;
        break;

      case 'url_cgu':
        nameReturned = this.alerts.formatURLcgu;
        break;

      case 'url_privacy':
        nameReturned = this.alerts.formatUrlPrivacy;
        break;

      default:
        nameReturned = '';
    }
    return nameReturned;
  }

  alertWarning(data) {
    this.list_invalid_string = '';
    this.list_invalid.forEach((element) => {
      if (typeof element.name === 'string')
        this.list_invalid_string =
          this.list_invalid_string +
          '<li style="list-style-type: none; font-size: 14px">' +
          this.getAttributName(element.name) +
          '</li>';
    });
    swal.fire({
      title: data,
      icon: 'warning',
      html: '<ul style="padding:0px">' + this.list_invalid_string + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e7955e',
    });
  }

  alertError(data, err?) {
    swal.fire({
      title: data,
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  retourToList() {
    this.routes.navigate(['/gestion-organisme']);
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  getTypeMaster(id): string {
    let libelle = '';
    if (this.listTypeOrganisme.length !== 0) {
      this.listTypeOrganisme.forEach((element) => {
        if (element.id === id) libelle = element.libelle;
      });
      return libelle;
    }
  }

  getLibelleId(event) {
    this.organisme.id_type = event.value;
    this.isTypeMaster = this.getTypeMaster(this.organisme.id_type);
    // if (this.isTypeMaster === 'Master') {
    //   this.organisme.active = '1';
    // } else {
    //   this.organisme.active = '0';
    // }
  }

  getTypeOrganisme() {
    this.apiAdminService.GetTypeOrganisme().subscribe((data: any) => {
      this.listTypeOrganisme = data;
    });
  }

  getListOrganisme() {
    const unsubscribeGetOrganismes = this.ApiCampagniesService.getOrganismes().subscribe(
      (Response: OrganismeListe[]) => {
        this.Organismes = Response;
        if (this.ModeAjout) {
          this.Organismes = this.Organismes.filter((Organisme) => Organisme.active === 1);
        }
      }
    );
    this.listeOfUnsubscribeWebService.push(unsubscribeGetOrganismes);
  }

  getListCampagnes() {
    const unsubscribegetListCampagne = this.apiOpportunitService
      .getListCampagne()
      .pipe(
        tap(({ campagne, hors_campagne }) => {
          this.cycleVie.campagne = campagne;
          this.cycleVie.hors_campagne = hors_campagne;
          this.SelectListCycleVie();
        })
      )
      .subscribe();
    this.listeOfUnsubscribeWebService.push(unsubscribegetListCampagne);
  }

  clearForm() {
    if (!this.ModeAjout) {
      Object.assign(this.organisme, this.initialorganisme);
      this.ngOnInit();
    } else this.organisme = new ListOrganisme();
  }

  SelectListCycleVie() {
    this.horsCamp
      ? (this.listCycleVieStr = Object.keys(this.cycleVie.hors_campagne))
      : (this.listCycleVieStr = Object.keys(this.cycleVie.campagne));
    this.horsCamp ? (this.listCycleVie = this.cycleVie.hors_campagne) : (this.listCycleVie = this.cycleVie.campagne);
    this.campagneId = '';
    this.SelectCycleVie();
  }

  SelectCycleVie() {
    this.listCampagnes = this.listCycleVie[this.selected_cyclevie];
  }

  /*  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.organisme.ville = adress.locality;
    this.organisme.street= adress.street_number+' '+adress.route
    this.organisme.code_postal=adress.postal_code
    this.organisme.latitude = adress.latitude;
    this.organisme.longitude = adress.longitude;
  } */

  selectRobot(event) {
    this.organisme.id_robot = event.id;
  }

  isActive(event) {
    if (event.target.checked) {
      this.organisme.active = '1';
    } else {
      this.organisme.active = '0';
    }
  }

  isAuthorized(event) {
    if (event.target.checked) {
      this.organisme.acces_geoprod = '1';
    } else {
      this.organisme.acces_geoprod = '0';
    }
  }

  isBeta(event) {
    if (event.target.checked) {
      this.organisme.beta = '1';
    } else {
      this.organisme.beta = '0';
    }
  }

  isPrePaye(event) {
    if (event.target.checked) {
      this.organisme.pre_pay = '1';
    } else {
      this.organisme.pre_pay = '0';
    }
  }

  alertConfirmUpdateActive(valid, directive) {
    if (this.organisme.active === '0' && this.ModeAjout === false) {
      swal
        .fire({
          title: this.alerts.confirmClosingSubOrganisme,
          icon: 'info',
          showConfirmButton: true,
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: this.buttons.fermer,
          confirmButtonText: this.buttons.confirmer,
          confirmButtonColor: '#28a8d2',
        })
        .then((result) => {
          if (result.isConfirmed) this.onCreateOrganisme(valid, directive);
        });
    } else {
      this.onCreateOrganisme(valid, directive);
    }
  }

  onCreateOrganisme(valid, directive) {
    this.organisme.form = new formbuilder();
    this.organisme.form.data = {};
    /*    const listForm =  [] ;
       this.organisme.form.schema.forEach((element , index) => {
          listForm[element.name] = this.model[index];
        });
        console.log(listForm) */
    this.organisme.form.data = this.model;
    this.couleurs = [];
    this.colorList = [];
    this.colorList.push(
      this.couleur1,
      this.couleur2,
      this.couleur3,
      this.couleur4,
      this.couleur5,
      this.couleur6,
      this.couleur7,
      this.couleur8,
      this.couleur9,
      this.couleur0
    );

    for (const i in this.colorList) {
      if (this.colorList[i] !== null) {
        if (typeof this.colorList[i] === 'string') {
          this.couleurs[i] = this.colorList[i];
        } else {
          this.couleurs[i] = '' + this.colorList[i];
        }
      }
    }
    this.parcours_souscription.couleurs = this.couleurs;
    this.parcours_souscription.font_family = this.font_familyy;
    this.espace_client.font_family = this.font_family;
    if (this.couleur_principale === null || this.couleur_principale === undefined) {
      this.espace_client.couleur_principale = '';
    } else if (this.couleur_principale.hex !== undefined) {
      this.espace_client.couleur_principale = '#' + this.couleur_principale.hex;
    } else if (typeof this.couleur_principale === 'string') {
      this.espace_client.couleur_principale = this.couleur_principale;
    } else {
      this.espace_client.couleur_principale = '';
    }
    if (this.couleur_secondaire === null || this.couleur_secondaire === undefined) {
      this.espace_client.couleur_secondaire = '';
    } else if (this.couleur_secondaire.hex !== undefined) {
      this.espace_client.couleur_secondaire = '#' + this.couleur_secondaire.hex;
    } else if (typeof this.couleur_secondaire === 'string') {
      this.espace_client.couleur_secondaire = this.couleur_secondaire;
    } else {
      this.espace_client.couleur_secondaire = '';
    }

    if (this.couleur_client1 === null || this.couleur_client1 === undefined) {
      this.espace_client.couleur3 = '';
    } else if (this.couleur_client1.hex !== undefined) {
      this.espace_client.couleur3 = '#' + this.couleur_client1.hex;
    } else if (typeof this.couleur_client1 === 'string') {
      this.espace_client.couleur3 = this.couleur_client1;
    } else {
      this.espace_client.couleur3 = '';
    }

    if (this.couleur_client2 === null || this.couleur_client2 === undefined) {
      this.espace_client.couleur4 = '';
    } else if (this.couleur_client2.hex !== undefined) {
      this.espace_client.couleur4 = '#' + this.couleur_client2.hex;
    } else if (typeof this.couleur_client2 === 'string') {
      this.espace_client.couleur4 = this.couleur_client2;
    } else {
      this.espace_client.couleur4 = '';
    }
    if (this.couleur_client3 === null || this.couleur_client3 === undefined) {
      this.espace_client.couleur5 = '';
    } else if (this.couleur_client3.hex !== undefined) {
      this.espace_client.couleur5 = '#' + this.couleur_client3.hex;
    } else if (typeof this.couleur_client3 === 'string') {
      this.espace_client.couleur5 = this.couleur_client3;
    } else {
      this.espace_client.couleur5 = '';
    }

    const listeOffre = new listetype();
    listeOffre.included = this.listColumnOffreDone;
    listeOffre.not_included = this.listColumnOffre;
    const listeContrat = new listetype();
    listeContrat.included = this.listColumnContratDone;
    listeContrat.not_included = this.listColumnContrat;
    this.espace_distributeur.list_offres = listeOffre;
    this.espace_distributeur.list_contrats = listeContrat;

    this.espace_distributeur.loyout = this.loyout;
    this.espace_distributeur.couleur_theme = this.couleur_theme;
    this.espace_distributeur.mode = this.mode;
    this.listTheme.forEach((element) => {
      if (element.name === this.couleur_theme) {
        this.espace_distributeur.couleur2 = element.color;
      }
    });
    if (this.couleurDist1 === null || this.couleurDist1 === undefined) {
      this.espace_distributeur.couleur1 = '';
    } else if (this.couleurDist1.hex !== undefined) {
      this.espace_distributeur.couleur1 = '#' + this.couleurDist1.hex;
    } else if (typeof this.couleurDist1 === 'string') {
      this.espace_distributeur.couleur1 = this.couleurDist1;
    } else {
      this.couleurDist1 = '';
    }
    // this.espace_distributeur.couleur1 = this.couleurDist1;
    this.brand.espace_client = this.espace_client;
    this.brand.espace_distributeur = this.espace_distributeur;
    this.brand.parcours_souscription = this.parcours_souscription;
    this.organisme.brand = this.brand;

    this.list_invalid = directive.filter((element) => element.control.status === 'INVALID');
    const listInvalid = directive.filter((element) => element.control.status === 'INVALID');
    listInvalid.forEach((element) => {
      const name = this.list_invalid[this.list_invalid.length - 1].name;
      if (
        (name === 'favicon' && this.organisme.favicon) ||
        (name === 'image1' && this.image1) ||
        (name === 'image2' && this.image2) ||
        (name === 'image3' && this.image3) ||
        (name === 'image4' && this.image4) ||
        (name === 'image5' && this.image5)
      )
        this.list_invalid.splice(this.list_invalid.length - 1, 1);
    });
    if (this.list_invalid.length > 0 && this.isTypeMaster !== 'distributeur') {
      this.alertWarning(this.alerts.formulaireinvalide + ' ' + this.alerts.fillObligInputs);
    } else {
      this.show_loading_add = true;
      this.organisme.campagnes = this.listIdCampagne;

      const formData: FormData = new FormData();

      formData.append('nom', this.organisme.nom);
      formData.append('dossier', this.organisme.dossier);
      formData.append('active', this.organisme.active);

      formData.append('acces_geoprod', this.organisme.acces_geoprod);
      formData.append('beta', this.organisme.beta);
      // formData.append('ville', this.organisme.ville);
      // formData.append('street', this.organisme.street);
      // formData.append('code_postal', this.organisme.code_postal);
      // formData.append('longitude', this.organisme.longitude);
      // formData.append('latitude', this.organisme.latitude);
      formData.append('id_type', this.organisme.id_type);
      formData.append('adresse', this.organisme.adresse);
      formData.append('libelle', this.organisme.libelle);
      this.listIdCampagne.forEach((campagne) => formData.append('campagnes', campagne));
      formData.append('logo', this.logo_organisme);
      formData.append('favicon', this.favicon_organisme);
      formData.append('tel', this.organisme.tel);
      formData.append('tel_gestion', this.organisme.tel_gestion);
      formData.append('mail', this.organisme.mail);
      formData.append('mail_gestion', this.organisme.mail_gestion);
      formData.append('capital', this.organisme.capital);
      formData.append('form_juridique', this.organisme.form_juridique);
      formData.append('rcs', this.organisme.rcs);
      formData.append('id_robot', this.organisme.id_robot);
      formData.append('code_intermediaire', this.organisme.code_intermediaire);
      formData.append('pre_pay', this.organisme.pre_pay);
      formData.append('description_ficheconseil', this.organisme.description_ficheconseil);
      formData.append('site', this.organisme.site);
      formData.append('orias', this.organisme.orias);
      formData.append('sarl', this.organisme.sarl);
      formData.append('siren', this.organisme.siren);
      formData.append('siret', this.organisme.siret);
      formData.append('url_cgu', this.organisme.url_cgu);
      formData.append('url_privacy', this.organisme.url_privacy);
      formData.append('id_externe', this.organisme.id_externe);
      formData.append('urlcomparateur', this.organisme.urlcomparateur);
      formData.append('urltarification', this.organisme.urltarification);
      formData.append('organisme_master', this.organisme.organisme_master);
      formData.append('background_image', this.background_image);
      formData.append('form', JSON.stringify(this.organisme.form));

      this.list.forEach((image: any, index: number) => formData.append(index.toString(), image));
      formData.append('brand', JSON.stringify(this.organisme.brand));
      if (this.ModeAjout) {
        const unsubscribeCreateOrganisme = this.apiOrgaismeServices.CreateOrganisme(formData).subscribe(
          (response) => {
            this.alertSuccess(this.alerts.organismeAdded, response.id_org);
            this.show_loading_add = false;
          },
          (error) => {
            this.alertError(this.alerts.errorAddingOrg, error.error.message);
            this.show_loading_add = false;
          }
        );
        this.listeOfUnsubscribeWebService.push(unsubscribeCreateOrganisme);
      } else {
        formData.append('id_type', this.organisme.id_type);
        const unsubscribeUpdateOrganisme = this.apiOrgaismeServices
          .UpdateOrganisme(this.organisme_id, formData)
          .subscribe(
            (response) => {
              this.alertSuccess(this.alerts.modifyOrg, response.id_org);
              this.show_loading_add = false;
            },
            (error) => {
              this.alertError(this.alerts.errorUpdatingOrg, error.error.message);
              this.show_loading_add = false;
            }
          );
        this.listeOfUnsubscribeWebService.push(unsubscribeUpdateOrganisme);
      }
    }
  }

  /*
   ***
   * List campagne seletcted
   ***
   */

  getListCampagne() {
    this.loaderListCompagnie = true;
    const unsubscribegetListCompagnes = this.apiOrgaismeServices.getListCompagnes().subscribe((Response: any[]) => {
      this.campagnie = Response.filter((elem) => elem.id !== null);
      this.loaderListCompagnie = false;
    });
    this.listeOfUnsubscribeWebService.push(unsubscribegetListCompagnes);
  }

  private _filterCampagne(): any[] {
    let filterValue = this.campagneCtrl.value?.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.campagnie.filter((utils) => utils.libelle?.toLowerCase().indexOf(filterValue) === 0);
  }

  removeCampagne(campagne: string): void {
    const index = this.campagnieSelected.indexOf(campagne);
    if (index >= 0) {
      this.campagnieSelected.splice(index, 1);
      this.listIdCampagne.splice(index, 1);
    }
  }

  addCampagne(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our campanie
    if ((value || '').trim()) {
      this.campagnieSelected.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.campagneCtrl.setValue(null);
  }

  selectedCampagne(event: MatAutocompleteSelectedEvent): void {
    this.campagnieSelected.push(event.option.viewValue);
    this.listIdCampagne.push(event.option.value);

    this.campagneInput.nativeElement.value = '';

    this.campagneCtrl.setValue(null);
  }

  expandName(name: string): string {
    if (name.length < 10) {
      return name;
    } else {
      return name.substring(name.lastIndexOf('/') + 1, name.length);
    }
  }

  /*
   ***
   * List User seletcted
   ***
   */

  getListUser(): void {
    const unsusbcribeGetUsers = this.apiOrgaismeServices.GetUsers().subscribe((data: any[]) => {
      this.userList = data.filter((elem) => elem.nom_user !== null);
    });
    this.listeOfUnsubscribeWebService.push(unsusbcribeGetUsers);
  }

  autoRemplirBySiren(siren?: number): void {
    if (siren?.toString().length < 9) {
      this.organisme.siret = '';
    } else if (siren?.toString().length === 9) {
      const unsubscribeGetEstablishmentInformations = this.apiOpportunitService
        .GetEstablishmentInformations(siren)
        .subscribe(
          (data: EstablishmentInformation) => {
            this.establishmentInformation = data;
            this.organisme.siret = data.unite_legale.etablissement_siege.siret;
            this.organisme.adresse =
              data.unite_legale.etablissement_siege.libelle_voie +
              ' ' +
              data.unite_legale.etablissement_siege.code_postal;
          },
          (error) => {
            if (error) {
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.info;
              notificationMessage.title = this.alerts.NoSirenFound;
              notificationMessage.message = siren.toString();
              this.notificationsService.sendMessage(notificationMessage);
            }
          }
        );
      this.listeOfUnsubscribeWebService.push(unsubscribeGetEstablishmentInformations);
    }
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  sendIndex(item: any, etat: string) {
    this.index = item;
    if (etat === 'getImage') {
      this.showImage1 = true;
    } else {
      this.showImage1 = false;
    }
  }

  keyPressNumbers(event) {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  dropListeContrat(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      event.container.data[event.currentIndex].order = event.currentIndex;
    } else {
      if (this.listColumnContratDone.length < 8) {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

        event.container.data[event.currentIndex].order = event.currentIndex;
        event.container.data[event.previousIndex].order = event.previousIndex;
      }
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      event.container.data[event.currentIndex].order = event.currentIndex;
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      event.container.data[event.currentIndex].order = event.currentIndex;
      event.container.data[event.previousIndex].order = event.previousIndex;
    }
  }

  deleleItem(item, type) {
    if (type === 'offre') {
      for (const i in this.listColumnOffreDone) {
        if (this.listColumnOffreDone[i].libelle === item) {
          this.listColumnOffreDone.splice(Number(i), 1);
          // this.listColumnOffre.push(this.listColumnOffreDone[i])
        }
      }
    } else {
      for (const i in this.listColumnContratDone) {
        if (this.listColumnContratDone[i].libelle === item) {
          this.listColumnContratDone.splice(Number(i), 1);
          // this.listColumnContrat.push(this.listColumnContratDone[i])
        }
      }
    }
  }

  goToConventions() {
    if (this.ModeAjout) {
      this.routes.navigate(['/gestion-organisme/convention/nouveau']);
    } else {
      this.routes.navigate(['/gestion-organisme/convention/' + this.organisme_id]);
    }
  }
}
