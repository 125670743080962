import { FicheBanqueComponent } from './../pages/gestion-banque/fiche-banque/fiche-banque/fiche-banque.component';
import { ProspectClientComponent } from './../pages/prospect-client/prospect-client.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './admin-layout.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { TasksComponent } from '../pages/tasks/tasks.component';
import { OpportunitiesComponent } from '../pages/opportunities/list-opportunite/opportunities.component';
import { AffairesComponent } from '../pages/affaires/affaire-list/affaires.component';
import { CalendarComponent } from '../pages/calendar/calendar.component';
import { MailevaComponent } from '../pages/maileva/list-maileva/maileva.component';
import { DriveComponent } from '../pages/drive/drive.component';
import { CommunicationComponent } from '../pages/communication/communication.component';
import { AffaireDetailsComponent } from '../pages/affaires/affaire-details/affaire-details.component';
import { TicketsAndSupportComponent } from '../pages/tickets-and-support/list-tickets/tickets-and-support.component';
import { ProfileComponent } from '../pages/profile/profile/profile.component';
import { WebmailComponent } from '../pages/webmail/webmail.component';

import { TicketsDetailsComponent } from '../pages/tickets-and-support/details-tickets/tickets-details.component';
import { GestionBanqueComponent } from '../pages/gestion-banque/gestion-banque.component';

import { MailevaDetailsComponent } from '../pages/maileva/details-maileva/maileva-details.component';
import { ErrorPageComponent } from '../components/error-page/error-page.component';
import { AuthGard } from '../shared/AuthGard/auth-gard.guard';
import { GestionCompaniesComponent } from '../pages/gestion-companies/gestion-companies.component';
import { CompaniesFicheComponent } from '../pages/gestion-companies/companies-fiche/companies-fiche.component';
import { GestionGammesComponent } from '../pages/gestion-gammes/gestion-gammes/gestion-gammes.component';
import { FicheGestionGammeComponent } from '../pages/gestion-gammes/fiche-gestion-gamme/fiche-gestion-gamme.component';
import { GoJsDiagramBpmComponent } from '../components/go-js-diagram-bpm/go-js-diagram-bpm.component';

import { AddNewMailevaComponent } from '../pages/maileva/add-new-maileva/add-new-maileva.component';
import { AddNewTicketsComponent } from '../pages/tickets-and-support/add-tickets/add-new-tickets.component';
import { FicheProspectComponent } from '../pages/fiche-prospect/fiche-prospect.component';
import { HistoriqueTransactionComponent } from '../pages/historique-transaction/historique-transaction.component';
import { GoJsDiagramBpmAffaireComponent } from '../components/go-js-diagram-bpm-affaire/go-js-diagram-bpm-affaire.component';

import { TarificateurVeloComponent } from '../pages/tarificateur-velo/tarificateur-velo.component';
import { ListGarantiesComponent } from '../pages/granties/list-garanties/list-garanties.component';
import { AddGarantieComponent } from '../pages/granties/add-garantie/add-garantie.component';
import { ListGroupGarantiesComponent } from '../pages/groupe-garanties/list-group-garanties/list-group-garanties.component';
import { AddGroupGarantieComponent } from '../pages/groupe-garanties/add-group-garantie/add-group-garantie.component';
import { AddConfigurationComponent } from '../pages/parametres/configuration/add-configuration/add-configuration.component';
import { EcheancesComponent } from '../pages/echeances/echeances.component';
import { WikiComponent } from '../pages/wiki/wiki.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        // DashboardComponent,
        // ,canActivate: [AuthGard]
      },
      {
        path: 'wiki',
        component: WikiComponent,
        // DashboardComponent,
        // ,canActivate: [AuthGard]
      },
      {
        path: 'gestion-roles',
        loadChildren: () =>
          import('../pages/parametres/utilisateurs/gestion-roles/gestion-roles.module').then(
            (m) => m.GestionRolesModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-menu',
        loadChildren: () =>
          import('../pages/parametres/utilisateurs/gestion-menu/gestion-menu.module').then((m) => m.GestionMenuModule),
        // canActivate: [AuthGard],
      },
      {
        path: 'configuration',
        component: AddConfigurationComponent,
      },
      {
        path: 'groupe-utilisateurs',
        loadChildren: () =>
          import('../pages/parametres/utilisateurs/groupe-utilisateurs/groupe-utilisateurs.module').then(
            (m) => m.GroupeUtilisateursModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'groupe-commissions',
        loadChildren: () =>
          import('../pages/parametres/utilisateurs/groupe-commission/groupe-commission.module').then(
            (m) => m.GroupeCommissonModule
          ),
        /* canActivate: [AuthGard], */
      },
      {
        path: 'templates',
        loadChildren: () =>
          import('../components/templates-editor/template-editor.module').then((m) => m.TemplateEditorModule),
        // canActivate: [AuthGard],
      },

      {
        path: 'gestion-organisme',
        loadChildren: () =>
          import('../pages/parametres/gestion-organisme/gestion-organisme.module').then(
            (m) => m.GestionOrganismeModule
          ),
      },
      {
        path: 'gestion-produit',
        loadChildren: () =>
          import('../pages/parametres/gestion-produit/gestion-produit.module').then((m) => m.GestionProduitModule),
        /*   ,canActivate: [AuthGard], */
      },
      {
        path: 'opportunities',
        component: OpportunitiesComponent,
        //  canActivate: [AuthGard],
      },
      {
        path: 'opportunities/add',
        loadChildren: () =>
          import('../pages/opportunities/add-opportunite/add-opportunite.module').then((m) => m.AddOpportuniteModule), // canActivate: [AuthGard],
      },
      {
        path: 'gestion-cycle-vies/preview/:id_cycle_vie/:title',
        component: GoJsDiagramBpmComponent,
        // canActivate: [AuthGard],
      },

      {
        path: 'gestion-cycle-vies-production/preview-Affaire/:id_cycle_vie/:title/:type',
        component: GoJsDiagramBpmAffaireComponent,
        // canActivate: [AuthGard],
      },

      {
        path: 'opportunities/details/:op_id',
        loadChildren: () =>
          import('../pages/opportunities/details-opportunite/opportunities-details.module').then(
            (m) => m.OpportunitiesDetailsModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'affaires/details/:affaire_id',
        loadChildren: () =>
          import('./../pages/affaires/affaire-details/affaire-details.module').then((m) => m.AffaireDetailsModule),
        component: AffaireDetailsComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'affaires',
        component: AffairesComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'agenda',
        component: CalendarComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'maileva',
        component: MailevaComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'maileva/add',
        component: AddNewMailevaComponent,
        // canActivate: [AuthGard],
      },

      {
        path: 'prospectClient/fiche-prospect/:id_entity/:prospect_id/:route',
        component: FicheProspectComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'maileva/details/:maileva_id',
        loadChildren: () =>
          import('../pages/maileva/details-maileva/maileva-details.module').then((m) => m.MailevaDetailsModule),
        component: MailevaDetailsComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'ticketsandsupport',
        component: TicketsAndSupportComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'ticketsandsupport/add',
        component: AddNewTicketsComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'ticketsandsupport/details/:tickets_id',
        loadChildren: () =>
          import('./../pages/tickets-and-support/details-tickets/tickets-details.module').then(
            (m) => m.TicketsDetailsModule
          ),
        component: TicketsDetailsComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'drive',
        component: DriveComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'communiques',
        component: CommunicationComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'webmail',
        component: WebmailComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'prospectClient',
        component: ProspectClientComponent,
        // canActivate: [AuthGard],
      },

      {
        path: 'profil',
        component: ProfileComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'echeance',
        component: EcheancesComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'tarificateur-velo',
        component: TarificateurVeloComponent,
      },
      {
        path: 'historique-transactions',
        component: HistoriqueTransactionComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-banque',
        component: GestionBanqueComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-utilisateurs',
        loadChildren: () =>
          import('./../pages/parametres/utilisateurs/gestion-utilisateurs/gestion-utilisateur.module').then(
            (m) => m.GestionUtilisateurModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-campagnes',
        loadChildren: () =>
          import('./../pages/parametres/gestion-campagnes/gestion-campagnes.module').then(
            (m) => m.GestionCampagnesModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-cycle-vies',
        loadChildren: () =>
          import('./../pages/parametres/gestion-cycle-vies/gestion-cycle-vies.module').then(
            (m) => m.GestionCycleViesModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-cycle-vies-production',
        loadChildren: () =>
          import('./../pages/parametres/getion-cycle-vies-production/getion-cycle-vies-production.module').then(
            (m) => m.GetionCycleViesProductionModule
          ),
        // canActivate: [AuthGard],
      },

      {
        path: 'gestion-documents',
        loadChildren: () =>
          import('./../pages/parametres/gestion-documents/gestion-documents.module').then(
            (m) => m.GestionDocumentsModule
          ),
        //   canActivate: [AuthGard],
      },

      {
        path: 'gestion-ligne-produit',
        loadChildren: () =>
          import('../pages/parametres/gestion-ligne-produit/gestion-ligne-produit.module').then(
            (m) => m.GestionLigneProduitModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-ligne-produit-affaires',
        loadChildren: () =>
          import('../pages/parametres/gestion-ligne-produit-affaire/gestion-ligne-produit.module').then(
            (m) => m.GestionLigneProduitAffairesModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-etat-opportunite',
        loadChildren: () =>
          import('./../pages/parametres/gestion-etat-opportunite/gestion-etat-opportunite.module').then(
            (m) => m.GestionEtatOpportuniteModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-status-affaire',
        loadChildren: () =>
          import('./../pages/parametres/gestion-status-affaire/gestion-status-affaire.module').then(
            (m) => m.GestionStatusAffaireModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-services-avant-ventes',
        loadChildren: () =>
          import('../pages/parametres/gestion-services-avant-ventes/gestion-service-opportunite.module').then(
            (m) => m.GestionServiceOpportuniteModule
          ),
        // canActivate: [AuthGard],
      },

      {
        path: 'gestion-services-apres-ventes',
        loadChildren: () =>
          import('../pages/parametres/gestion-services-apres-ventes/gestion-service-apres-ventes.module').then(
            (m) => m.GestionServiceApresVentesModule
          ),
        // canActivate: [AuthGard],
      },

      {
        path: 'gestion-tags',
        loadChildren: () =>
          import('./../pages/parametres/gestion-tags/gestion-tags.module').then((m) => m.GestionTagsModule),
        // canActivate: [AuthGard],
      },

      {
        path: 'profile',
        component: ProfileComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-companies',
        component: GestionCompaniesComponent,
      },
      {
        path: 'gestion-companies/fiche/:id',
        component: CompaniesFicheComponent,
      },
      {
        path: 'gestion-banque/fiche/:id',
        component: FicheBanqueComponent,
      },
      {
        path: 'gestion-gammes',
        component: GestionGammesComponent,
      },
      {
        path: 'gestion-gammes/fiche/:id',
        component: FicheGestionGammeComponent,
      },
      {
        path: 'gestion-categorie',
        loadChildren: () =>
          import('./../pages/parametres/gestion-categorie/gestion-categorie.module').then(
            (m) => m.GestionCategorieModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'gestion-categorie-ticket',
        loadChildren: () =>
          import('./../pages/parametres/gestion-categorie-ticket/gestion-categorie-ticket.module').then(
            (m) => m.GestionCategorieTicketModule
          ),
        // canActivate: [AuthGard],
      },
      {
        path: 'garantie',
        component: ListGarantiesComponent,
        // canActivate: [AuthGard],
      },

      {
        path: 'add-garantie/:id',
        component: AddGarantieComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'groupe-garantie',
        component: ListGroupGarantiesComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'add-groupe-garantie/:id',
        component: AddGroupGarantieComponent,
        // canActivate: [AuthGard],
      },

      {
        path: 'tasks',
        component: TasksComponent,
        // canActivate: [AuthGard],
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminLayoutRoutingModule {}
